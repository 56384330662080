import React from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Privacy() {
  return (
    <>
      <Navbar transparent />
      <main className="privacy-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1640158615573-cd28feb1bf4e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3270&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={require("assets/img/undraw_Security_on_re_e491.png").default}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-120-px"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-20">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700">
                    Privacy Policy
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                    Bangalore, India
                  </div>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700">
                        Last Updated: May 01, 2022
                      </h3>
                      <p className="text-base leading-relaxed mt-6 mb-4 text-lightBlue-800">
                        At Vivasa Information Systems Pvt Ltd. (”We”) are committed to protecting and respecting your privacy. This notice sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.
                      </p>
                      <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700">
                        General
                      </h3>
                      <ul className="text-sm">
                        <li className="mt-2">
                          This Privacy Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents/ records in various statutes as may be amended by the Information Technology Act, 2000 from time to time. This Privacy Policy does not require any physical, electronic or digital signature and is a legally binding document between the User and Us.
                        </li>
                        <li className="mt-2">
                          Vivasa Information Systems Pvt Ltd, an Indian company incorporated as per the provisions of the Companies Act, 2013 having registered office at #B-504, ND Passion, Haralur Road, Kudlu Village, Bangalore 560068 and its technical processors (“Company”, “We”, “Our” or “Us”) are committed to protecting "Your" privacy and grant importance to the confidentiality of Your data over internet. This privacy policy (“Privacy Policy”) applies to all services offered to persons using or visiting ("Users", “You” or “Your”) our internet website https://vivasa.in (“Website”).
                        </li>
                        <li className="mt-2">
                          The purpose of this Privacy Policy is to inform the User about the processing of his/her personal data collected when using the services available on the Platform. We collect and utilize Your Personal Information (defined hereinafter ) and sensitive Personal Information only to enhance the operation(s) of the Platform, allowing You to use all the listed features and allowing "Us" to respond to Your requests if any. Personal Information is used strictly in line with our business purposes and to provide You with useful features.
                        </li>
                        <li className="mt-2">
                          When the User accesses another website through a link or a URL available on the Platform, this Privacy Policy does not apply anymore. We reserve the right to modify at any time this Policy Privacy and to inform User of the same by any means.
                        </li>
                        <li className="mt-2">
                          In this Privacy Policy, “Personal Information” means any information relating to an identified or identifiable User, who can be identified, directly or indirectly, in particular by reference to his name, his email address, his IP address (“Personal Information”). Personal Information does not include anonymized information that has been appropriately processed to make it non-identifiable in order to irreversibly prevent its identification.
                        </li>
                        <li className="mt-2">
                          We respect the privacy of Our Users and are committed to protecting it in all respects. The information about the User is collected by Us as (i) information supplied by Users; (ii) information automatically tracked during User's navigation on the Platform; and/ or (iii) communicated directly to Us via e-mail or telephone or another channel.
                        </li>
                        <li className="mt-2">
                          User acknowledges that this Privacy Policy, together with terms and conditions, forms Our agreement with the User in relation to his use of the Platform.
                        </li>
                        <li className="mt-2">
                          For any service that User may use, We only collect the data that is strictly necessary (i) for the delivery of the services, (ii) for the performance of a contract for which User is a party, (iii) for which User has given consent for such processing of data, or (iv) for compliance with a legal obligation to which "We" are subject.
                        </li>
                      </ul>

                      <h3 className="text-xl font-semibold leading-normal mt-4 mb-2 text-blueGray-700">
                        Information Collected
                      </h3>
                      <ul className="text-sm">
                        <li className="mt-2">
                          This is information about you that you give us by filling in forms on our site – https://vivasa.in – (our site) or by corresponding with us by phone, e-mail or otherwise. It includes information you provide when you register to use our site, to enter our database, use our on-boarding portal for pre-engagement/employment screening, subscribe to our services, attend our events, participate in discussion boards or other social media functions either on our site or on our social media feeds, enter a competition, promotion or survey, or when you report a problem with our site.
                        </li>
                        <li className="mt-2">
                          The information you give us or we collect about you may include your name, address, private and corporate e-mail address and phone number, financial information, compliance documentation and references verifying your qualifications, curriculum vitae, links to your professional profiles available in the public domain e.g. LinkedIn, Twitter, business Facebook or corporate website.
                        </li>
                        <li className="mt-2">
                          The information we obtain about you from other sources such as LinkedIn, corporate websites, job board websites, online CV libraries, your business card, or personal recommendations. In this case, we will inform you, by sending you this privacy notice, within a maximum of 30 days of collecting the data of the fact we hold personal data about you, the source the personal data originates from and whether it came from publicly accessible sources, and for what purpose we intend to retain and process your personal data.

                        </li>
                        <li className="mt-2">
                          All such information is stored and/or used for processing orders and under no circumstance are any of these details made available in the public domain. However, We may use this information to share with You additional and/ or upcoming information regarding the services of Platform.
                        </li>
                        <li className="mt-2">
                          Our website may contain links to third-party websites or applications. The User agrees and understands that privacy policies of these websites are not under "Our" control and the User understands that once he/she leaves Our servers, use of any information provided by the User shall be governed by the privacy policy of the operator of the site used by him.
                        </li>
                        <li className="mt-2">
                          We may disclose any information "We" deem necessary, in Our sole discretion, to comply with any applicable law, regulation, legal process or governmental request. Such transfer or disclosure shall be for lawful purposes only. Further, such transfer or disclosure of Your sensitive personal information to other third parties shall only be done once it is ensured that the third party receiving such information has implemented the same level of security practices as we have i.e. not below the standards of IS/ISO/IEC 27001.
                        </li>
                      </ul>
                      <h3 className="text-xl font-semibold leading-normal mt-4 mb-2 text-blueGray-700">
                        Security
                      </h3>
                      <ul className="text-sm">
                        <li className="mt-2">
                          The security of Users Personal Information is important to Us. We follow generally accepted industry standards to protect the Personal Information submitted to "Us", both during transmission and once "We" receive it.
                        </li>
                        <li className="mt-2">
                          Although We make best possible efforts to store Personal Information in a secure operating environment that is not open to the public, User must understand that there is no such thing as complete security, and We do not guarantee that there will be no unintended disclosures of Personal Information if such disclosure is not due to the reasons attributable to Us. If We become aware that Users Personal Information has been disclosed in a manner not in accordance with this Privacy Policy, We will use reasonable efforts to notify such "User" of the nature and extent of such disclosure as soon as reasonably possible and as permitted by law.
                        </li>
                        <li className="mt-2">
                          Depending on the processing of Personal Information, the data may be subject to a different storage period. Most of the Personal Information is stored for a duration of 2 (two) years from "Users" last use of the Website, or the App or service. At the end of the storage period, the Personal Information is purged and therefore deleted from Our database.
                        </li>
                        <li className="mt-2">
                          We do not sell Users Personal Information to any advertisers or any third party.
                        </li>
                      </ul>

                      <h3 className="text-xl font-semibold leading-normal mt-4 mb-2 text-blueGray-700">
                        Children Protection
                      </h3>
                      <ul className="text-sm">
                        <li className="mt-2">
                          The Platform is not intended for children, and We do not intentionally/knowingly collect Personal Information if You are under the minimum age, as specified under the relevant applicable laws, statutes and/or regulations.
                        </li>
                      </ul>
                      <h3 className="text-xl font-semibold leading-normal mt-4 mb-2 text-blueGray-700">
                        Feedback And Grievances
                      </h3>
                      <ul className="text-sm">
                        <li className="mt-2">
                          If You have any questions, comments or concerns about "Our" privacy practices or would like to provide feedback on the services offered on Platform, please contact Our grievance officer by e-mail at contact@vivasa.in
                        </li>
                      </ul>



                      <h2 className="text-xl font-semibold leading-normal mt-12 text-center mb-2 text-blueGray-700">
                        End
                      </h2>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
