import React from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";
import { Link } from "react-router-dom";

export default function Profile() {
  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={require("assets/img/yes-you-can.jpg").default}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0">
                      <button
                        className="bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Freshers
                      </button>
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700">
                    Mihira Bootcamp
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i className="fas fa-calendar mr-2 text-lg text-blueGray-400"></i>{" "}
                    Last Updated: June 15, 2022
                  </div>
                  {/* <div className="mb-2 text-blueGray-600 mt-10">
                    <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                    Solution Manager - Creative Tim Officer
                  </div> */}

                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-justify">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-2 text-lg text- leading-relaxed text-blueGray-500">
                        <i>If you are going to try, go all the way. Otherwise, don't even start. It could mean not eating for three or four days. It could mean freezing on a park bench. It could mean jail. It could mean mockery & isolation. </i>
                      </p>
                      <p className="mb-2 text-lg leading-relaxed text-blueGray-500">
                        <i>And you'll do it, despite rejection and the worst odds. And it will be better than anything else you can imagine. You will be alone with the gods and the nights will flame with fire.</i>
                      </p>
                      <Link to={{ pathname: "https://en.wikipedia.org/wiki/Charles_Bukowski" }} target="_blank" >
                        <footer className="mb-2 block text-lightBlue-600">
                          - <cite>Charles Bukowski
                          </cite>
                        </footer>
                      </Link>

                      <h3 className="text-xl font-semibold leading-normal mt-8 text-blueGray-700">
                        Overview
                      </h3>
                      <p className="text-base leading-relaxed mt-4 mb-2 text-lightBlue-800">
                        The initial days of a developer new to an IT job are not easy. If landing a job with a good company is one thing, succeeding in this job may feel like another daunting task.
                      </p>
                      <p className="text-base leading-relaxed mt-4 mb-2 text-lightBlue-800">
                        Numerous weeks (or months) of training and hundreds of hours of video tutorials are not adequate for becoming a developer. Getting the first job does not mean the end of learning. Instead, it is a continuation of learning skills that are most relevant for the job.
                      </p>
                      <p className="text-base leading-relaxed mt-4 mb-2 text-lightBlue-800">
                        Below is a compilation of a list of topics relevant to a software engineer. This list can serve as a guidepost in unearthing a topic or two that could be relevant for you on the job.
                      </p>

                      <h3 className="text-xl font-semibold leading-normal mt-8 mb-6 text-blueGray-700">
                        Know the key roles in a software development team
                      </h3>
                      <p className="text-base leading-relaxed mt-4 mb-2 text-lightBlue-800">
                        You will rarely work in isolation. Whatever work you do, will be done by collaborating with others. Other team members may or may not be doing work similar to yours. Knowing who your team is composed of is essential.
                      </p>
                      <ol className="text-base leading-relaxed">
                        <li className="mt-4 mb-2">
                          <b>Product Owner:</b> This is a strategic role responsible for developing a vision of a product’s function and operation. PO may not exist in a project undertaken for an external client
                        </li>
                        <li className="mt-4 mb-2">
                          <b>Project Manager:</b> A PM oversees the progress of the project. They make project goals their own and lead the planning, executing, monitoring, controlling, and closing of projects
                        </li>
                        <li className="mt-4 mb-2">
                          <b>Technical Architect:</b> This role is responsible for defining the overall structure of a software system. An architect makes high-level design choices and frames technical standards
                        </li>
                        <li className="mt-4 mb-2">
                          <b>UI/UX Designer:</b> The UI/UX designer is responsible for designing the experience of an end-user (of a mobile app or other application) in an optimal way
                        </li>
                        <li className="mt-4 mb-2">
                          <b>QA Engineer:</b> A QA engineer ensures that the software meets the expectations of its end users before its release. They perform a series of checks to validate if the software works as expected.
                        </li>
                        <li className="mt-4 mb-2">
                          <b>Business Analyst:</b> BA needs to understand and explain the new feature updates to clients and take feedback for further development. BA also facilitates dialogues and ensures that the development team understands the client requirements correctly to avoid confusion later
                        </li>
                        <li className="mt-4 mb-2">
                          <b>Software Developer:</b> You are in charge of writing code and developing the software products your company will eventually sell. You may be involved in building a piece of software from scratch or engage in maintaining an already built software system
                        </li>
                      </ol>

                      <h3 className="text-xl font-semibold leading-normal mt-8 mb-6 text-blueGray-700">
                        Choose the right tools for the Job
                      </h3>

                      <p className="text-base leading-relaxed mt-4 mb-2 text-lightBlue-800">
                        Your tools are your weapons. Like all the weapons that enhance the abilities of their carrier, your tools can immensely add to your productivity. Know the various tools that other developers use regularly.
                      </p>

                      <ul className="text-base leading-relaxed">
                        <li className="mt-4 mb-2">
                          <b>IDE:</b> The integrated development environment is a set of bundled tools for you to code, format, and debug the source code
                        </li>
                        <li className="mt-4 mb-2">
                          <b>Terminal Emulator:</b> If you’re serious about learning to code — or just understanding computer technology — you absolutely need to get to know your command line [link]
                        </li>
                      </ul>

                      <h3 className="text-xl font-semibold leading-normal mt-8 mb-6 text-blueGray-700">
                        Learning about the work your team is doing
                      </h3>

                      <p className="text-base leading-relaxed mt-4 mb-2 text-lightBlue-800">
                        As a fresher, you will rarely find yourself starting a project from scratch. Some teams would have been in place, some work may have been done. You will merely work with seniors in learning what is done so far and what is forthcoming. Asking your team the following questions could help you get started.
                      </p>

                      <ul className="text-base leading-relaxed">
                        <li className="mt-4 mb-2">
                          What is it that the team is building? What is the goal? What has been done so far?
                        </li>
                        <li className="mt-4 mb-2">
                          What is the purpose of the project/product? Who is the User who will benefit?
                        </li>
                        <li className="mt-4 mb-2">
                          What pain point is being solved for the User?
                        </li>
                        <li className="mt-4 mb-2">
                          What is the current functionality that is already built? How to test it?
                        </li>
                        <li className="mt-4 mb-2">
                          Identify the team. Know who decides the requirements, who gives technical design, who validates the functionality, and who approves the deployment?
                        </li>
                      </ul>

                      <h3 className="text-xl font-semibold leading-normal mt-8 mb-6 text-blueGray-700">
                        Concepts that will strengthen your foundation
                      </h3>

                      <ul className="text-base leading-relaxed">
                        <li className="mt-4 mb-2">
                          The role of the Operating System in executing a piece of software
                        </li>
                        <li className="mt-4 mb-2">
                          What is a Process and how is it different from a Thread?
                        </li>
                        <li className="mt-4 mb-2">
                          How does a development team collaborate on the same codebase? The role of source code version control
                        </li>
                        <li className="mt-4 mb-2">
                          How the web works
                        </li>
                        <li className="mt-4 mb-2">
                          Fundamentals of containers and virtual machines
                        </li>
                      </ul>

                      <p className="text-base leading-relaxed mt-4 mb-2 text-lightBlue-800">
                        Hope this list kindles your curiosity a tiny bit. Even a small attempt to study these concepts will certainly give a new engineer an immense return in the long run. Happy coding!
                      </p>

                      {/* <a
                        href="#pablo"
                        className="font-normal text-lightBlue-500"
                        onClick={(e) => e.preventDefault()}
                      >
                        Show more
                      </a> */}
                    </div>                    
                  </div>
                  <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                    <div className="flex flex-wrap justify-center">
                      <div className="w-full lg:w-8/12 px-4">
                        <p className="mb-2 text-lg text-leading-relaxed text-blueGray-500">
                          End
                        </p>
                      </div>
                    </div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
